@use 'config'
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i&display=swap')

body
  margin: 0
  padding-bottom: config.size(footer-height)
  color: config.color(text)
  background: config.color(background)
  font-family: 'Roboto', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  > header
    display: flex
    flex-flow: row nowrap
    align-items: flex-end
    padding: 2vw
    color: config.color(text dark)
    background: config.color(background light)

    .logo
      flex: 0 0 auto
      width: 12vw

    h1
      margin: 0
      flex: 1 1 auto
      padding-left: 3vw
      font-size: 3vw

  > footer
    position: fixed
    bottom: 0
    width: 100%
    height: config.size(footer-height)
    border-top: 1px solid #444
    box-sizing: border-box
    padding: 1vw 2vw
    bacgrkound: config.color(background)
    font-size: 80%

// Import Elements
@import elements/buttons
@import elements/forms
