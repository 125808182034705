@use '../config'

.estimate-bus-arrival
  $border-width: 0.2vw
  $border-color: config.color(text light)
  $button-size: 4.2vw
  $icon-size: 1vw

  > .settings
    position: relative
    color: config.color(text light)

    &::before, &::after
      content: ''
      position: absolute
      top: $button-size * 0.25 - $border-width
      left: ($button-size - $icon-size) * 0.5
      width: $icon-size
      height: $border-width
      margin-left: $button-size * -0.5
      background: $border-color
      z-index: -1

    &::after
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out
      opacity: 0

    > button
      display: flex
      flex-flow: row nowrap
      align-items: center
      margin: 0 0 0.5vw $button-size * -0.5
      padding: 0
      background: none
      font:
        size: 70%
        weight: 400

      &::before
        content: ''
        position: relative
        margin-right: 1.5vw
        width: $button-size
        height: $button-size / 2
        box-sizing: border-box
        font:
          size: 1.5vw
          weight: 700
        border: $border-width solid $border-color
          top-width: 0
          bottom-left-radius: $button-size / 2
          bottom-right-radius: $button-size / 2
        z-index: -2

    > form
      border: solid $border-color
        width: 0 $border-width $border-width
      box-sizing: border-box
      padding: 2vw

      > p
        margin-top: 0

      > label
        width: 50%
        margin-top: 0
        box-sizing: border-box
        float: left

        &:nth-of-type(1)
          padding-right: 0.5vw

        &:nth-of-type(2)
          padding-left: 0.5vw

        > select
          font-size: config.size(text)

    &:not(.open)

      &::before, &::after
        background: config.color(background)

      &::after
        transform: rotate(90deg)
        opacity: 1

      > button
        &::before
          background: $border-color

        &:not(:hover)
          color: rgba(config.color(text light), 0.1)

          &::before
            opacity: 0.1
