@use '../config'

.app
  display: flex
  flex-flow: row wrap
  padding: 0 3vw
  font-size: config.size(text)

  > .estimate-bus-arrival
    flex: 1 0 50%
    padding: 0 2vw 2vw
    box-sizing: border-box
