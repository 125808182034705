form label
  display: flex
  flex-flow: column-reverse nowrap
  margin: 1em 0

  > input, > textarea, > select, > span
    flex: 0 0 auto

  > input, > textarea, > select
    &[required] + span::after
      content: ' *'
      color: red

