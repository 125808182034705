@use '../config'

.bus-arrival-results

  &:not(.no-results)
    margin: 0

  > dt
    margin-top: 1.5vw
    font-size: 2.5vw

    &::after
      content: ':'

  > dd
    margin: 0
    color: yellow

    &, > ol > li:first-child
      font-size: 7vw

    > ol
      margin: 0
      padding: 0
      list-style: none
      font-size: 3.5vw

      > li
        &:first-child
          display: inline-block
          width: 100%

        &:not(:first-child)
          display: inline
          color: config.color(text light)

        &:nth-child(n+3)::before
          content: ', '

        &:nth-child(n+5)
          display: none

