@use '../config'

button
  border: none
    radius: 0.5vw
  padding: 0.5vw 1vw
  color: config.color(text light)
  background-color: #c03
  font:
    family: 'Roboto', sans-serif
    size: config.size(text)
    weight: 700
  cursor: pointer
